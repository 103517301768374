<template>
	<div>
		<sb-operatip>当月剩余可免费使用<span class="primary">15</span>次，超出部分<span class="primary">0.5</span>元/次。</sb-operatip>
	</div>
</template>

<script>
	import SbOperatip from '../../../components/opera_tip/opera_tip.vue'
	export default {
		data() {
			return {

			}
		},
		methods: {
		
		},
		components: {
			SbOperatip
		},
		watch: {
			// data(newValue, oldValue) {
				
			// }
		},
		filters: {
			// fnName: function(value) {
			// 	return value;
			// }
		},
		beforeCreate() {

		},
		created() {

		},
		beforeMount() {

		},
		mounted() {

		},
		beforeUpdate() {
		
		},
		updated() {
		
		},
		beforeDestroy() {
		
		},
		destroyed() {
		
		},
		computed: {
			// name() {
			// 	return this.data
			// }
		},
		
	}
</script>

<style lang="less" scoped>
	
</style>
