<template>
	<div>
		<div class="tipBox" :style="tipStyle?tipStyle:''">
			<i class="iconfont icon-jinggao"></i>
			<slot></slot>
		</div>
	</div>
</template>

<script>
	// beforeCreate created beforeMount mounted 都不会触发。
	
	export default {
		data() {
			return {

			}
		},
		props: {
			tipStyle:{
				default:()=>{
					return ''
				}
			}
		},
		methods: {

		},
		components: {

		},
		activated: {
			// keep-alive组件 激活 时调用。
			//该钩子在服务器端渲染期间不被调用。

		},
		deactivated: {
			//keep-alive组件  停用 时调用。
			//该钩子在服务器端渲染期间不被调用。
		},
		watch: {
			// data(newValue, oldValue) {

			// }
		},
		filters: {
			// fnName: function(value) {
			// 	return value;
			// }
		},
		beforeCreate() {

		},
		created() {

		},
		beforeMount() {

		},
		mounted() {

		},
		beforeUpdate() {

		},
		updated() {

		},
		beforeDestroy() {

		},
		destroyed() {

		},
		computed: {
			// name() {
			// 	return this.data
			// }
		},

	}
</script>

<style lang="less" scoped>
	.primary{
		color: @primaryColorQian;
	}
	.tipBox {
		padding: 10px 15px;
		border: 1px solid #ff714b;
		background-color: #ffefe6;
		border-radius: 4px;
		margin-bottom: 10px;
		display: flex;
		align-items: center;
		.icon-jinggao {
			color: @primaryColorQian;
			display: inline-block;
			border-radius: 50%;
			width: 18px;
			height: 18px;
			text-align: center;
			line-height: 18px;
			margin-right: 10px;
		}
	}
</style>
